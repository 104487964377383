export enum UserType {
  SYSTEM = "system",
  RETAILER = "retailer",
  DISTRIBUTOR = "distributor",
}

export enum UserRole {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  READER = "reader",
  WRITER = "writer",
}
